import React, { Component } from 'react'

import {Loading} from "carbon-components-react"
import { base } from './firebase/firebase'
import { firebase } from "./firebase"
import Dashboard from './components/Dashboard'
import TopBar from './components/TopBar'
import Login from './components/Login'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedProjectId: null,
      loading: true,
      switchingProject: false,
      connectedUser: null
    }
    this.metaDocument = null

    this.selectProject = this.selectProject.bind(this)
  }

  initDataSync = () => {
    this.setState({loading: true})
    base.bindCollection('projects', {
      context: this,
      state: 'projects',
      withRefs: true,
      withIds: true,
      then(data) {
        this.setState(state => ({
          ...state,
          loading: false
        }))

        if (this.state.projects === null || this.state.projects.length === 0) { return }
        this.setSelectedProject()
      },
      onFailure(err) {
        //handle error
        console.error(err)
      }
    })
  }

  UNSAFE_componentWillMount() {
    firebase.auth.onAuthStateChanged(connectedUser => {
      if (connectedUser) {
        this.setState(() => ({ connectedUser, loading: false }))
        this.initDataSync()
      } else {
        this.setState(() => ({ connectedUser: null, loading: false }))
      }
    })
  }

  setSelectedProject = () => {
    base
      .get('meta', {context: this, withIds: true})
      .then(data => {
        if (data !== null && data !== undefined && data.length !== 0) {
          this.metaDocument = data[0].id

          const lastProjectViewed = data[0].lastProjectViewed
          if (lastProjectViewed !== null && lastProjectViewed !== undefined) {
            this.setState({selectedProjectId: lastProjectViewed})
            return
          }
        }
        this.setState({selectedProjectId: this.state.projects[0].id})
      })
      .catch(() => {
        this.setState({selectedProjectId: this.state.projects[0].id})
      })
  }

  selectProject(projectId) {
    if (this.state.selectedProjectId !== null && this.state.selectedProjectId === projectId) {
      return
    }

    if (this.metaDocument !== null) {
      base.updateDoc(`meta/${this.metaDocument}`, { lastProjectViewed: projectId })
    } else {
      base.addToCollection('meta', { lastProjectViewed: projectId })
    }

    this.setState({ selectedProjectId: null, switchingProject: true })
    setTimeout(() => {
      this.setState({ selectedProjectId: projectId, switchingProject: false })
    }, 300)
  }

  render() {
    const { selectedProjectId, projects, loading, switchingProject, connectedUser } = this.state

    if (loading) {
      return <Loading withOverlay />
    }

    if (connectedUser === null) {
      return <Login loginSuccess={this.initDataSync} />
    }

    const selectedProject = selectedProjectId === null ? null : projects.find(p => p.id === selectedProjectId)

    return (
      <div className="App">
        <TopBar projects={projects} selectProject={this.selectProject} selectedProject={selectedProject} />
        {selectedProject === null ? null : <Dashboard project={selectedProject} />}
        <Loading active={switchingProject} withOverlay />
      </div>
    )
  }
}

export default App
