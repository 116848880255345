import React from 'react'

import { base } from '../firebase/firebase'
import NewSessionModal from './NewSession'
import CurrentSession from './CurrentSession'

class Session extends React.Component {
  state = {currentSession: null, loading: true}

  componentDidMount() {
    base.listenToCollection(`/session`,
      {
        context: this,
        withIds: true,
        then(data) {
          if (data !== null && data.length !== 0) {
            if (data[0].length === 0) {
              this.setState({currentSession: null, loading: false})
            } else {
              this.sessionBinding = base.syncDoc(`session/${data[0].id}`,
                {
                  context: this,
                  withIds: true,
                  state: 'currentSession',
                  then() {
                    this.setState({loading: false})
                  }
                })
            }
          } else if (this.sessionBinding !== undefined) {
            base.removeBinding(this.sessionBinding)
            this.setState({currentSession: null, loading: false})
          } else if (data.length === 0) {
            this.setState({currentSession: null, loading: false})
          }
        },
        onFailure(err) {
          console.error(err)
          this.setState({loading: false})
        }
      })
  }

  componentWillUnmount() {
    // If a session is started and we leave, we should save it to firestore
    // const { currentSession } = this.state
    // if (currentSession !== null) {
    //   base.updateDoc(`session/${currentSession.id}`, {...currentSession, lastActivated: new Date()})
    // }
  }

  render() {
    const { project } = this.props
    const { currentSession, loading } = this.state

    if (loading) {
      return null
    }

    return (
      <div>
        {currentSession === null || (typeof currentSession === 'object' && Object.keys(currentSession).length === 0)
          ? <NewSessionModal project={project}/>
          : <CurrentSession session={currentSession} />
        }
      </div>
    )
  }
}

export default Session
