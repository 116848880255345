import React from 'react'
import { Dropdown } from "carbon-components-react"

class Projects extends React.Component {
  render() {
    const { projects, selectProject, selectedProject } = this.props

    const items = projects.map(p => ({id: p.id, text: p.name}))
    const selectedItem = selectedProject === null ? selectedProject : {id: selectedProject.id, text: selectedProject.name}

    return (
      <Dropdown
      label=""
      id="projectSelect"
      type="inline"
      style={{width: 200}}
      items={items}
      itemToString={item => (item ? item.text : '')}
      onChange={({ selectedItem }) =>
        setTimeout(() => selectProject(selectedItem.id), 200)
      }
      selectedItem={selectedItem}
    />
    )
  }
}

export default Projects
