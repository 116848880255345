import React from 'react'
import { base } from '../firebase/firebase'
import {Modal, Button, TextInput, DatePicker, DatePickerInput} from "carbon-components-react"
import TimeInput from "./TimeInput"


class NewWorkPeriodModal extends React.Component {
  state = {isOpen: false}

  closeModal = () => this.setState({isOpen: false})
  openModal = () => this.setState({isOpen: true})

  render() {
    const { isOpen } = this.state

    return (
      <div>
        <Modal
          aria-label="Add a work period"
          open={isOpen}
          className="workPeriodModalWrapper"
          modalHeading="Work period report"
          primaryButtonText="Save"
          secondaryButtonText="Cancel"
          onRequestClose={() => {this.closeModal(); this.newWPRef.clearForm()}}
          onRequestSubmit={() => {this.newWPRef.createWorkPeriod(this.closeModal)}}
        >
          <NewWorkPeriodForm
            {...this.props}
            ref={instance => this.newWPRef = instance}/>
        </Modal>
        <Button kind="ghost" size="small" className="newWorkPeriod" onClick={this.openModal}>+</Button>
      </div>
    )
  }
}


class NewWorkPeriodForm extends React.Component {
  initialState = {
    description: '',
    date: new Date(),
    duration: 0
  }
  state = {...this.initialState}

  handleChange = e => {
    const { name, type, value } = e.target
    const val = type === 'number' ? parseFloat(value) : value
    this.setState({ [name]: val })
  }

  handleDateChange = e => {
    if (e.length === 0) {
      return
    }

    this.setState({date: e[0]})
  }

  handleDurationChange = e => {
    const { value } = e
    if (value !== null && !isNaN(value)) {
      this.setState({duration: value})
    }
  }

  clearForm = () => {
    this.setState({...this.initialState})
  }

  validateWorkPeriod = () => {
    const { date, duration, description } = this.state

    return date !== null && duration !== 0 && description.trim() !== ''
  }

  createWorkPeriod = (onSuccess=() => {}) => {
    if (!this.validateWorkPeriod()) {
      return
    }

    const { description, date, duration } = this.state
    const { project, task } = this.props

    base
      .addToCollection(`projects/${project.id}/tasks/${task.id}/workPeriods`, {description, date, duration})
      .then(() => {
        console.log('Create new work period: success')
        this.clearForm()
        onSuccess()
      })
      .catch(err => {
        console.log('Create new work period: error')
        console.error(err)
      })
  }

  render() {
    const { date, description } = this.state

    return (
      <form className="newWorkPeriodForm" onSubmit={() => this.createWorkPeriod()} noValidate>
        <TextInput
          id="description"
          labelText="Description"
          type="text"
          name="description"
          value={description}
          onChange={this.handleChange}
        />

        <DatePicker
          datePickerType="single"
          dateFormat="d/m/Y"
          onChange={this.handleDateChange}
          value={date}>
          <DatePickerInput
            id="date"
            labelText="Date"/>
        </DatePicker>

        <TimeInput
          onChange={this.handleDurationChange}
          label="Duration"
        />
      </form>
    )
  }
}

export default NewWorkPeriodModal
export { NewWorkPeriodForm }