import React from 'react'
import {v4 as uuid} from 'uuid'
import {TextInput, Modal, Button} from "carbon-components-react"
import {base} from "../firebase/firebase"
import TimeInput from "./TimeInput"



class NewTaskModal extends React.Component {
  state = {isOpen: false, multiline: false}

  closeModal = () => this.setState({isOpen: false})
  openModal = () => this.setState({isOpen: true})
  switchForm = () => this.setState({multiline: !this.state.multiline})

  render() {
    const { isOpen, multiline } = this.state
    const { project } = this.props

    const singleLineContent = (
      <div>
        <Button kind="ghost" size="small" onClick={this.switchForm} className="multilineSwitch">
          Multi-insert
        </Button>
        <NewTaskForm ref={ins => this.newTaskRef = ins} project={project} inline={multiline} />
      </div>
    )

    const multiLineContent = (
      <div>
        <Button kind="ghost" size="small" onClick={this.switchForm} className="multilineSwitch">
          Single-insert
        </Button>
        <MultiNewTaskForm ref={ins => this.newTaskRef = ins} project={project} />
        <Button kind="ghost" size="small" className="addLineButton" onClick={() => this.newTaskRef.addForm()}>+ Add more</Button>
      </div>
    )

    return (
      <div>
        <Modal
          aria-label="Create a new task"
          className={multiline ? 'largeModal': ''}
          open={isOpen}
          modalHeading="Create a new task"
          primaryButtonText="Create"
          secondaryButtonText="Cancel"
          onRequestSubmit={
            () => { this.newTaskRef.createTask(this.closeModal) }
          }
          onRequestClose={
            () => {
              this.closeModal()
              this.newTaskRef.clearForm()
            }
          }
        >
          {multiline ? multiLineContent : singleLineContent}
        </Modal>
        <Button kind="ghost" size="small" onClick={this.openModal}>Add task</Button>
      </div>
    )
  }
}


class NewTaskForm extends React.Component {
  initialState = {
    name: '',
    expectedDuration: 0,
    category: '',
    errors: []
  }
  state = {...this.initialState}

  formInputValidation = {
    name: value => value.trim().length > 2,
    category: value => value.trim() !== '',
    expectedDuration: value => true
  }

  clearForm = () => {
    this.setState({...this.initialState})
  }

  handleChange = e => {
    const { name, type, value } = e.target
    const val = type === 'number' ? parseFloat(value) : value
    this.validateForm(name, val)
    this.setState({ [name]: val })
  }

  handleDurationChange = e => {
    const { value } = e
    if (value !== null && !isNaN(value)) {
      this.setState({expectedDuration: value})
    }
  }

  validateForm = (name, value) => {
    const { errors } = this.state

    if (!this.formInputValidation[name](value)) {
      if (errors.findIndex(e => e === name) === -1) {
        this.setState({errors: [...errors, name]})
      }
    } else {
      this.setState({errors: errors.filter(e => e !== name)})
    }
  }

  isValid = () => {
    return this.state.errors.length === 0
  }

  createTask = (onSuccess=() => {}) => {
    const { project } = this.props
    const { name, expectedDuration, category } = this.state

    if (!this.isValid()) {
      return
    }

    base
      .addToCollection(`projects/${project.id}/tasks`, { name, expectedDuration, category })
      .then(() => {
        console.log('Create new task: success')
        this.clearForm()
        onSuccess()
      })
      .catch(err => {
        console.log('Create new task: error')
        console.error(err)
      })
  }

  render() {
    const { name, category, errors } = this.state
    const { inline, removeLine, formId, removeLineDisabled } = this.props

    const isInvalid = target => errors.findIndex(e => e === target) !== -1

    return (
      <form className={"newTaskForm " + (inline ? "form-inline" : "")} onSubmit={() => this.createTask()} noValidate>
        <TextInput
          invalid={isInvalid('name')}
          id="name"
          labelText="Name"
          hideLabel={inline}
          placeholder={inline ? 'Name' : ''}
          type="text"
          name="name"
          value={name}
          onChange={this.handleChange}
          required
        />

        <TimeInput
          onChange={this.handleDurationChange}
          label="Duration"
        />

        <TextInput
          invalid={isInvalid('category')}
          id="category"
          labelText="Category"
          hideLabel={inline}
          placeholder={inline ? 'category' : ''}
          type="text"
          name="category"
          value={category}
          onChange={this.handleChange}
          required
        />
        {inline ? <Button kind="ghost" className="removeLineButton" onClick={() => removeLine(formId)} disabled={removeLineDisabled}>-</Button> : null}
      </form>
    )
  }
}


class MultiNewTaskForm extends React.Component {
  constructor(props) {
    super(props)

    const initialFormsCount = 3

    this.state = {forms: Array(initialFormsCount).fill().map(() => uuid())}
    this.refCollection = {}
  }

  addForm = () => {
    const { forms } = this.state
    this.setState({forms: [...forms, uuid()]})
  }

  removeForm = (formId) => {
    delete this.refCollection[formId]
    this.setState({forms: this.state.forms.filter(f => f !== formId)})
  }

  clearForm = () => {
    Object.values(this.refCollection).map(ref => ref !== null && ref.clearForm())
  }

  createTask = (onSuccess=() => {}) => {
    const isValid = ref => {
      if (ref !== null){
        return ref.isValid()
      }
      return true
    }
    const anyError = Object.values(this.refCollection).map(isValid).some(e => e === false)

    if (!anyError) {
      Object.values(this.refCollection).map(ref => ref !== null && ref.createTask(onSuccess))
    }
  }

  render() {
    const { project } = this.props
    const { forms } = this.state

    const removeLineDisabled = forms.length <= 1

    return (
      <div>
        {forms.map(formId =>
          <NewTaskForm
            key={formId}
            formId={formId}
            ref={ins => this.refCollection[formId] = ins}
            project={project}
            removeLine={this.removeForm}
            removeLineDisabled={removeLineDisabled}
            inline />
        )}
      </div>
    )
  }
}

NewTaskForm.defaultProps = {inline: false, removeLine: () => {}, formId: null, removeLineDisabled: false}

export default NewTaskModal
export { NewTaskForm, MultiNewTaskForm }