import React from 'react'
import PropTypes from 'prop-types'
import { Accordion } from 'carbon-components-react'
import { groupBy } from 'lodash'
import Task from './Task'
import { base } from '../firebase/firebase'
import ProjectTimeUsage from "./ProjectTimeUsage"

class Dashboard extends React.Component {
  state = {tasks: []}

  UNSAFE_componentWillMount() {
    const { project } = this.props

    base.bindCollection(`projects/${project.id}/tasks`, {
      context: this,
      state: 'tasks',
      withRefs: true,
      withIds: true,
      then() {
        const { tasks } = this.state

        const totalTimeUsed = parseInt(tasks.reduce((acc, cur) => acc + (cur.timeUsage || 0), 0))
        if (project.timeUsage !== totalTimeUsed) {
          this.updateProjectTimeUsage(totalTimeUsed)
        }
      }
    })
  }

  updateProjectTimeUsage = timeUsage => {
    base.updateDoc(`projects/${this.props.project.id}`, {timeUsage})
  }

  render() {
    const { project } = this.props

    if (project === null) {
      return null
    }

    if (this.state.tasks.length === 0) {
      return <p className="empty">No task yet - create one !</p>
    }


    const categories = groupBy(this.state.tasks, t => t.category)

    return (
      <div className="content">
        <ProjectTimeUsage project={project} />

        {Object.entries(categories).map(([category, tasks]) => (
          <div key={category} style={{marginBottom: '15px'}}>
            <h4 style={{marginLeft: '2%', marginBottom: '5px'}}>{category}</h4>
            <Accordion>
              {tasks.map(task => <Task task={task} project={project} key={task.id}/>)}
            </Accordion>
          </div>
          )
        )}
      </div>
    )
  }
}

export default Dashboard
Dashboard.propTypes = {
  project: PropTypes.object
}