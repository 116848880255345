import React from 'react'

import { base } from '../firebase/firebase'
import {Modal, Button, TextInput} from 'carbon-components-react'


class NewProjectModal extends React.Component {
  state = {isOpen: false}

  closeModal = () => this.setState({isOpen: false})
  openModal = () => this.setState({isOpen: true})

  render() {
    const { isOpen } = this.state

    const onSuccess = projectId => {
      this.props.selectProject(projectId)
      this.closeModal()
    }

    return (
      <div>
        <Modal
          aria-label="Create a new project"
          open={isOpen}
          modalHeading="New project"
          primaryButtonText="Create"
          secondaryButtonText="Cancel"
          onRequestClose={() => {this.closeModal(); this.newProjectRef.clearForm()}}
          onRequestSubmit={() => {
            this.newProjectRef.createProject(onSuccess)
          }}>
          <NewProjectForm ref={ins => this.newProjectRef = ins}/>
        </Modal>
        <Button kind="ghost" size="small" onClick={this.openModal}>Create new project</Button>
      </div>
    )
  }
}


class NewProjectForm extends React.Component {
  initialState = {name: '', description: '', errors: []}
  state = {...this.initialState}

  formInputValidation = {
    name: value =>  value.trim().length > 2 && value.trim().length < 20,
    description: value =>  value.length < 200
  }

  validateForm = (name, value) => {
    const { errors } = this.state

    if (!this.formInputValidation[name](value)) {
      if (errors.findIndex(e => e === name) === -1) {
        this.setState({errors: [...errors, name]})
      }
    } else {
      this.setState({errors: errors.filter(e => e !== name)})
    }
  }

  handleChange = e => {
    const { name, value } = e.target

    this.validateForm(name, value)
    this.setState({ [name]: value })
  }

  clearForm = () => {
    this.setState({...this.initialState})
  }

  createProject(onSuccess=() => {}) {
    const { name, description, errors } = this.state

    if (errors.length !== 0) {
      return
    }

    base
      .addToCollection('projects', { name, description })
      .then(res => {
        console.log('Create new project: success')
        this.clearForm()
        onSuccess(res.id)
      })
      .catch(err => {
        console.log('Create new project: error')
        console.error(err)
    })
  }

  render() {
    const { name, description, errors } = this.state

    const isInvalid = target => errors.findIndex(e => e === target) !== -1

    return (
      <form className="newProjectForm" onSubmit={() => this.createProject()} noValidate>
        <TextInput
          invalid={isInvalid('name')}
          id="projectName"
          labelText="Name"
          type="text"
          name="name"
          value={name}
          onChange={this.handleChange}
        />

        <TextInput
          invalid={isInvalid('description')}
          id="projectDescription"
          labelText="Decription"
          type="textare"
          name="description"
          value={description}
          onChange={this.handleChange}
        />
      </form>
    )
  }
}

export default NewProjectModal
export { NewProjectForm }
