import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'carbon-components-react'
import  { differenceInSeconds } from 'date-fns'
import { base } from "../firebase/firebase"
import SessionTimer from "./SessionTimer"

class CurrentSession extends React.Component {
  getNewDuration = () => {
    const { session } = this.props
    const { lastActivated } = session
    const now = new Date()

    let duration = lastActivated === null ? 0 : differenceInSeconds(now, new Date(lastActivated.seconds * 1000))
    duration += session.duration
    return duration
  }

  stop = () => {
    const { session } = this.props
    if (session === null || !session.active) { return }
    this.SessionTimerRef.stopTimer()

    const duration = this.getNewDuration()
    base.updateDoc(`session/${session.id}`, {
      ...session,
      duration,
      active: false,
      lastActivated: null
    })
  }

  end = () => {
    const { session } = this.props
    if (session === null) { return }
    this.SessionTimerRef.stopTimer()

    base
      .addToCollection(`projects/${session.project}/tasks/${session.task}/workPeriods`, {
        description: session.description,
        date: new Date(),
        duration: session.duration
      })
      .then(() => {
        base.removeDoc(`session/${session.id}`)
      })
      .catch(err => {
        alert('Unable to end session')
        console.error(err)
      })
  }

  resume = () => {
    const { session } = this.props
    if (session === null || session.active) { return }
    this.SessionTimerRef.startTimer()

    base.updateDoc(`session/${session.id}`, {
      ...session,
      active: true,
      lastActivated: new Date()
    })
  }

  render() {
    const { session } = this.props

    const lastActivateddiff = session.lastActivated !== null && session.lastActivated !== undefined
      ? differenceInSeconds(new Date(), new Date(session.lastActivated.seconds * 1000))
      : 0
    const currentDuration = lastActivateddiff + session.duration

    return (
      <div>
        <div className="inline currentSessionText">
          <p>
            <span>{session.description}</span>
            <SessionTimer ref={ins => this.SessionTimerRef = ins} time={currentDuration} running={session.active}/>
          </p>
        </div>
        <div className="inline">
          {session.active
            ? <Button kind="ghost" size="small" onClick={this.stop}>Stop session</Button>
            : <div><Button kind="ghost" size="small" onClick={this.resume}>Resume</Button><Button kind="ghost" small onClick={this.end}>End</Button></div>
          }
        </div>
      </div>
    )
  }
}

export default CurrentSession
CurrentSession.propTypes = {
  session: PropTypes.object.isRequired
}