import React from 'react'
import PropTypes from 'prop-types'


class PrettyTime extends React.Component {
  getFormattedTime = () => {
    let { time, colon, forceSeconds, ignoreSeconds } = this.props

    const hours = parseInt(time / 3600)
    const hh = colon || hours !== 0 ? ("0" + hours).slice(-2) : ''
    const hprefix = hh === '' ? '' : colon ? ':' : 'h'
    time %= 3600

    const minutes = parseInt(time / 60)
    const mm = colon || hours !== 0 || minutes !== 0 ? ("0" + minutes).slice(-2) : ''
    let mprefix = mm === '' ? '' : colon ? ':' : ' '
    if (mprefix === ' ' && minutes !== 0 && hours === 0) {
      mprefix = 'm '
    }
    time %= 60

    const seconds = time
    const ss = colon || seconds !== 0 ? ("0" + seconds).slice(-2) : ''
    const sprefix = ss === '' && !forceSeconds ? '' : colon ? '' : 's'

    return hh + hprefix + mm + mprefix + (ignoreSeconds ? '' : ss + sprefix)
  }

  render() {
    const time = this.getFormattedTime()
    return (
      <span>{time}</span>
    )
  }
}

export default PrettyTime
PrettyTime.defaultProps = {
  colon: true,
  forceSeconds: true,
  ignoreSeconds: false
}
PrettyTime.propTypes = {
  time: PropTypes.number.isRequired,
  colon: PropTypes.bool,
  forceSeconds: PropTypes.bool,
  ignoreSeconds: PropTypes.bool
}
