import React from 'react'
import {Button, TextInput} from 'carbon-components-react'
import { auth } from '../firebase'


const INITIAL_STATE = {
  email: '',
  password: '',
  error: null
}

class Login extends React.Component {
  state = {...INITIAL_STATE}

  validateForm = () => {
    const { email, password } = this.state
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const error = !emailPattern.test(String(email).toLowerCase()) || password.trim() === ''

    if (error) {
      this.setState({error: 'Invalid credentials'})
    }
    console.log(error)
    return error
  }

  doLogin = e => {
    e.preventDefault()
    if (this.validateForm()) {
      return
    }

    const { email, password } = this.state

    auth.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }))
        this.props.loginSuccess()
      })
      .catch(error => {
        this.setState({error: 'Invalid email or password. Please try again', password: ''})
      })
  }

  handleChange = e => {
    const {name, value} = e.target

    this.setState({[name]: value, error: null})
  }

  render() {
    const { email, password, error } = this.state

    return (
      <div>
        <form onSubmit={this.doLogin} className="loginForm" noValidate>
          <TextInput
            type="email"
            id="email"
            labelText="Email"
            name="email"
            value={email}
            onChange={this.handleChange}
            required
          />

          <TextInput
            type="password"
            id="password"
            labelText="Password"
            name="password"
            value={password}
            onChange={this.handleChange}
            required
          />
          {error !== null && <p>{error}</p>}
          <Button kind="ghost" size="small" type="submit">Log in</Button>
        </form>
      </div>
    )
  }
}

export default Login