import React from 'react'
import { AccordionItem, Table, TableHead, TableRow,
  TableHeader, TableBody } from 'carbon-components-react'

import WorkPeriod from "./WorkPeriod"
import { base } from '../firebase/firebase'
import NewWorkPeriodModal from "./NewWorkPeriod";
import PrettyTime from "./PrettyTime";

class Task extends React.Component {
  state = {workPeriods: []}
  tableHeader = [
    {key: 'date', header: 'Date'},
    {key: 'description', header: 'Comment'},
    {key: 'duration', header: 'Duration'},
    {key: 'ref', header: 'ref'},
  ]

  UNSAFE_componentWillMount() {
    const { project, task } = this.props

    base.bindCollection(`projects/${project.id}/tasks/${task.id}/workPeriods`, {
      context: this,
      state: 'workPeriods',
      withRefs: true,
      withIds: true,
      query: ref => ref.orderBy('date', 'desc'),
      then() {
        const { workPeriods } = this.state

        const totalTimeUsed = parseInt(workPeriods.reduce((acc, cur) => acc + cur.duration, 0))
        if (task.timeUsage !== totalTimeUsed) {
          this.updateTimeUsage(totalTimeUsed)
        }
      }
    })
  }

  updateTimeUsage = timeUsage => {
    const { project, task } = this.props

    base.updateDoc(`projects/${project.id}/tasks/${task.id}`, {timeUsage})
  }

  render() {
    const { task, project } = this.props
    const { workPeriods } = this.state

    const totalSecondsUsed = task.timeUsage || 0
    const totalTimeUsed = task.expectedDuration === 0 ? 0 : (totalSecondsUsed * 100) / task.expectedDuration

    const title = (
      <div>
        <span>{task.name}</span>
        {totalTimeUsed === 0
          ? (
            <span className="pull-right">
              <PrettyTime time={totalSecondsUsed} forceSeconds={false} colon={false} ignoreSeconds={true} />
            </span>
          )
          : (
            <span className="pull-right">
              <span>
                <PrettyTime time={totalSecondsUsed} forceSeconds={false} colon={false} ignoreSeconds={true} />
                /
                <PrettyTime time={task.expectedDuration} forceSeconds={false} colon={false} ignoreSeconds={true} />

              </span>
              <span className="smaller"> ({totalTimeUsed.toFixed(0)}%)</span>
            </span>
        )}
      </div>
    )

    const wpTable = (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader className="w20">Date</TableHeader>
            <TableHeader className="w60">Description</TableHeader>
            <TableHeader className="w20">Duration</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {workPeriods.map(wp => <WorkPeriod period={wp} key={wp.id}/>)}
        </TableBody>
      </Table>
    )

    const noWp =  <p className="empty">No work done on this task yet !</p>

    return (
      <AccordionItem title={title} className="tasks">
        <NewWorkPeriodModal task={task} project={project} className="" />

        {workPeriods.length === 0 ? noWp : wpTable}
      </AccordionItem>
    )
  }
}

export default Task
