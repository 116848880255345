import React from 'react'
import { TableRow, TableCell } from 'carbon-components-react'

import { base } from '../firebase/firebase'
import PrettyTime from './PrettyTime'

class WorkPeriod extends React.Component {
  constructor(props) {
    super(props)

    this.state = {workPeriod: {...props.period}}
  }

  UNSAFE_componentWillMount() {
    const { period } = this.props

    base.syncDoc(period.ref, {
      context: this,
      state: 'workPeriod'
    })
  }

  render() {
    const { workPeriod } = this.state

    return (
      <TableRow>
        <TableCell>{new Date(workPeriod.date.seconds * 1000).toDateString()}</TableCell>
        <TableCell>{workPeriod.description}</TableCell>
        <TableCell><PrettyTime time={workPeriod.duration} colon={false} forceSeconds={false} /></TableCell>
      </TableRow>
    )
  }
}

export default WorkPeriod
