import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Projects from './Projects'
import NewProjectModal from "./NewProject"
import NewTaskModal from "./NewTask"
import Session from './Session'

const NavBar = styled.div`
background-color: rgb(216,219,223);
margin-bottom: 30px;
padding: 12px;
`

class TopBar extends React.Component {
  render() {
    const { projects, selectProject, selectedProject } = this.props

    return (
      <NavBar>
        <div className="inline" style={{width:'150px'}}>
          <Projects
            projects={projects}
            selectProject={selectProject}
            selectedProject={selectedProject} />
        </div>
        <div className="inline" style={{marginRight: '5px'}}>
          <NewProjectModal selectProject={selectProject} />
        </div>
        <div className="inline">
          {selectedProject === null ? null : <NewTaskModal project={selectedProject}/>}
        </div>
        <div className="sessionButton">
          {selectedProject === null ? null : <Session project={selectedProject}/>}
        </div>
      </NavBar>
    )
  }
}

export default TopBar

TopBar.propTypes = {
  projects: PropTypes.array,
  selectProject: PropTypes.func.isRequired,
  selectedProject: PropTypes.object
}

TopBar.defaultProps = {
  projects: [],
  selectedProject: null
}