import React from 'react'
import { NumberInput } from "carbon-components-react"
import PropTypes from 'prop-types'


class TimeInput extends React.Component {
  state = {hours: 0, minutes: 0}


  calcSeconds = (hours, minutes) => {
    return hours * 3600 + minutes * 60
  }

  handleMinutes = e => {
    if (e.target.value === '' || e.target.value === null || e.target.value === undefined) {
      return
    }

    const value = parseInt(e.target.value)
    let { hours } = this.state
    let minutes = value
    if (value >= 60) {
      hours = parseInt(value / 60)
      minutes = value % 60
    }
    this.setState({hours, minutes})

    const seconds = this.calcSeconds(hours, minutes)
    this.props.onChange({value: seconds, type: this.props.type, name: this.props.name})
  }

  handleHours = e => {
    if (e.target.value === '' || e.target.value === null || e.target.value === undefined) {
      return
    }

    const value = parseInt(e.target.value)
    const { minutes } = this.state
    this.setState({hours: value, minutes})

    const seconds = this.calcSeconds(value, minutes)
    this.props.onChange({value: seconds, type: this.props.type, name: this.props.name})
  }

  render() {
    const { label } = this.props
    const { hours, minutes } = this.state

    return (
      <div>
        {label === null ? null : <span className="timeInputLabel bx--label">{label}</span>}
        <div className="timeInput">
          <NumberInput
            className="timeInputField"
            id="hours"
            name="hours"
            type="number"
            min={0}
            value={hours}
            step={1}
            label="Hours"
            onChange={this.handleHours}
          />
          <NumberInput
            className="timeInputField"
            id="minutes"
            name="minutes"
            type="number"
            min={0}
            value={minutes}
            step={15}
            label="Minutes"
            onChange={this.handleMinutes}
          />
        </div>
      </div>
    )
  }
}

export default TimeInput

TimeInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string
}

TimeInput.defaultProps = {
  type: 'number',
  name: 'time',
  onChange: () => {},
  label: null
}