import React from 'react'
import PrettyTime from './PrettyTime'

function ProjectTimeUsage({ project }) {
  if (project.timeUsage === undefined || project.timeUsage === 0) {
    return null
  }

  const oneDay = 28800
  const inDays = project.timeUsage < oneDay ? null : (
    <span>(~{(project.timeUsage / oneDay).toFixed(1)} days)</span>
  )

  return <div className="pull-right timeusage">
    <span>Total time used: <PrettyTime
      time={project.timeUsage}
      colon={false}
      ignoreSeconds={true}
      forceSeconds={false} />
    </span>
    {inDays}
  </div>
}


export default ProjectTimeUsage