import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import Rebase from 're-base'

let app = firebase.initializeApp({
  apiKey: "AIzaSyDoDuYePV_yctQY9vOeb53hH087rpFIQDU",
  authDomain: "timetrack-b91bb.firebaseapp.com",
  databaseURL: "https://timetrack-b91bb.firebaseio.com",
  projectId: "timetrack-b91bb",
  storageBucket: "timetrack-b91bb.appspot.com",
  messagingSenderId: "752757156774"
})
const db = firebase.firestore(app)
db.settings({})
const base = Rebase.createClass(db)
const auth = firebase.auth()

export { db, base, auth }
