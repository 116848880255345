import React from 'react'
import { base } from '../firebase/firebase'
import {Modal, Button, TextInput, Checkbox, Dropdown} from 'carbon-components-react'

class NewSessionModal extends React.Component {
  state = {isOpen: false}

  closeModal = () => this.setState({isOpen: false})
  openModal = () => this.setState({isOpen: true})

  render() {
    const { project } = this.props
    const { isOpen } = this.state

    return (
      <div>
        <Modal
          aria-label="Start a new work session"
          className="startSessionModal"
          open={isOpen}
          modalHeading="Work session"
          primaryButtonText="Start"
          secondaryButtonText="Cancel"
          onRequestClose={() => {this.closeModal(); this.newSessionRef.clearForm()}}
          onRequestSubmit={() => this.newSessionRef.startSession(this.closeModal)}
          >
          <NewSessionForm ref={ins => this.newSessionRef = ins} project={project} />
        </Modal>
        <Button kind="ghost" size="small" onClick={this.openModal}>Start session</Button>
      </div>
    )
  }
}


class NewSessionForm extends React.Component {
  initialState = {
    availableTasks: [],
    tasksReady: false,
    description: '',
    task: null,
    stopOnClose: false,
    errors: []
  }
  state = {...this.initialState}
  formInputValidation = {
    description: value => value.trim().length > 2,
    task: value => value !== null,
    stopOnClose: value => value !== null
  }

  constructor(props) {
    super(props)

    base.listenToCollection(`/projects/${props.project.id}/tasks`, {
      context: this,
      withIds: true,
      then(data) {
        this.setState({tasksReady: true, availableTasks: [...data]})
      }
    })
  }

  validateForm = (name, value) => {
    const { errors } = this.state

    if (!this.formInputValidation[name](value)) {
      if (errors.findIndex(e => e === name) === -1) {
        this.setState({errors: [...errors, name]})
      }
    } else {
      this.setState({errors: errors.filter(e => e !== name)})
    }
  }

  isValid = () => {
    return this.state.errors.length === 0
  }

  clearForm = () => {
    this.setState({...this.initialState})
  }

  getTask = taskId => {
    const { availableTasks } = this.state
    return availableTasks.find(item => item.id === taskId)
  }

  handleChange = e => {
    const { name, type, value } = e.target
    const val = type === 'number' ? parseFloat(value) : value
    this.validateForm(name, val)
    this.setState({ [name]: val })
  }

  startSession = (onSuccess=() => {}, onError=() => {}) => {
    const { errors, description, stopOnClose, task} = this.state
    const { project } = this.props

    if (errors.length !== 0) {
      return
    }

    const extraData = {
      project: project.id,
      created_at: new Date(),
      active: true,
      duration: 0,
      lastActivated: new Date()
    }

    // TODO: check if one session already started before creating it
    base
      .addToCollection('session', {description, stopOnClose, task: task.id, ...extraData})
      .then(res => {
        console.log('Start session success')
        console.log(res)
        onSuccess()
      })
      .catch(err => {
        console.log('Start session error')
        console.error(err)
        onError()
      })
  }

  render() {
    const { description, errors, stopOnClose, task, availableTasks, tasksReady} = this.state

    if (tasksReady && availableTasks.length === 0) {
      return <p>Error: No task available, please create tasks before starting to work</p>
    }

    const isInvalid = target => errors.findIndex(e => e === target) !== -1
    const taskItem = availableTasks
      .sort((a, b) => a.category.localeCompare(b.category))
      .map(t => ({id: t.id, text: `[${t.category}] ${t.name}`}))
    const selectedTask = task === null ? task : {id: task.id, text: task.name}

    return (
      <form className="startSessionForm" onSubmit={this.startSession} noValidate>
        <TextInput
          invalid={isInvalid('description')}
          id="sessionDescription"
          labelText="Description"
          type="text"
          name="description"
          value={description}
          onChange={this.handleChange}
        />

        <Checkbox
          id="sessionStopOnClose"
          name='stopOnClose'
          labelText="Pause session on window close"
          value={stopOnClose}
          onChange={e => this.setState({stopOnClose: e})}
        />

        <Dropdown
          id="sessionTask"
          name="task"
          label="Task"
          items={taskItem}
          itemToString={item => (item ? item.text : '')}
          onChange={({ selectedItem }) => this.setState({task: this.getTask(selectedItem.id)})}
          selectedItem={selectedTask}
        />
    </form>
    )
  }
}


export default NewSessionModal
