import React from 'react'
import PrettyTime from "./PrettyTime"

class SessionTimer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {currentTime: props.time}

    this.timerId = null
    if (props.running) {
      this.startTimer()
    }
  }

  componentWillUnmount() {
    this.stopTimer()
  }

  startTimer = () => {
    this.timerId = setInterval(() => {
      this.setState({currentTime: this.state.currentTime + 1})
    }, 1000)
  }

  stopTimer = () => {
    if (this.timerId !== null) {
      clearInterval(this.timerId)
    }
  }

  render() {
    const { currentTime } = this.state

    return <PrettyTime time={currentTime} />
  }
}

export default SessionTimer
